import React from 'react';
import GoogleLogin from 'react-google-login';


const handleGoogleResponse = (response : any) => {
  console.log(response);
};

const Auth = () => {
    return (
        <GoogleLogin
            clientId="1098675102224-2mg06l9nbjmoec54obha5bso1gcksq9h.apps.googleusercontent.com"
            // buttonText="Login"
            onSuccess={handleGoogleResponse}
            onFailure={handleGoogleResponse}
            isSignedIn={false}
            cookiePolicy={'single_host_origin'}
        />
    )
};

export default Auth;