import React from 'react';
import logo from './logo.svg';
import './App.css';
import Auth from './components/Auth'

function App() {
  return (
    <div className="App">
      {/* Google OneTap Auth */}
      {/* <div id="g_id_onload"
        data-client_id="1098675102224-2mg06l9nbjmoec54obha5bso1gcksq9h.apps.googleusercontent.com"
        data-login_uri="https://api.mrkdll.com/google-auth"
        data-state_cookie_domain="*mrkdll.com*"
        data-auto_select="false"
        data-cancel_on_tap_outside="false"
        data-test_item="test_value" >
      </div> */}

      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>

      <Auth />
    </div>
  );
}

export default App;
